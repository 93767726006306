<template>
    <div v-if="activeGw" id="fixtures" class="box">
        <div class="flex-default">
            <div>
                <h2>
                    GW {{ activeGw.number }} - Fixtures
                </h2>
                <template v-if="showInfo">
                    <div v-if="activeGw.doubles_count || activeGw.blanks_count">
                        {{ activeGw.doubles_count }} Doubles
                        - {{ activeGw.blanks_count }} Blanks
                    </div>
                    <span class="locked-at">Deadline: {{ deadlineText }}</span>
                </template>
            </div>
            <gameweek-pagination
                v-if="showPagination"
                :active-gw="activeGwId"
                :max-number="maxGwNumber"
                @goTo="activeGwId = $event"
            ></gameweek-pagination>
        </div>
        <template v-if="Object.values(activeFixtures).length > 0">
            <div v-for="(fixtures,timestamp) in activeFixtures">
                <div class="date-header">
                    <h3>{{ prettyDate(timestamp) }}</h3>
                </div>
                <template v-for="fixture in fixtures" :key="fixture.id">
                    <fixture-list-row
                        :fixture="fixture"
                    ></fixture-list-row>
                    <fixture-list-players
                        v-if="playerData.picks || playerData.fixtures.hasOwnProperty(fixture.id)"
                        :fixture="fixture"
                        :player-fixtures="playerData.fixtures[fixture.id]"
                        :picks="playerData.picks"
                    ></fixture-list-players>
                </template>

            </div>
        </template>
        <div v-else class="p-3">
            Fixture Details Unavailable
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref, watch} from "vue";
import FixtureListRow from "./FixtureListRow.vue";
import dayjs from 'dayjs';
import GameweekPagination from "./GameweekPagination.vue";
import FixtureListPlayers from "@/components/FixtureListPlayers.vue";

export default {
    components: {FixtureListPlayers, GameweekPagination, FixtureListRow},
    props: {
        showPagination: {
            type: Boolean,
            default: true
        },
        showInfo: {
            type: Boolean,
            default: true
        },
        gameweekId: {
            type: Number,
            default: null
        },
        playerFixtures: {
            type: Array,
            default: null
        },
        picks: {
            type: Object,
            default: null
        },
        loadPicks: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const activeGwId = ref(props.gameweekId || store.fixturesGwId || store.transfersGwId)
        const activeGw = computed(() => store.gameweeks[activeGwId.value])

        const authEntry = computed(() => {
            return store.authEntry
        })

        const playerData = ref({
            fixtures: {},
            picks: null
        })

        const setPlayerData = function(fixtures, picks) {
            playerData.value.fixtures = _.groupBy(fixtures, 'fixture_id')
            playerData.value.picks = _.size(picks) > 0
                ? _.sortBy(picks, pick => store.players[pick.player_season_id].position_sort)
                : null
        }

        onMounted(() => {
            setPlayerData(props.playerFixtures, props.picks)

            if (props.loadPicks && !playerData.value.picks) {
                loadAuthPicks()
            }
        })

        watch(activeGwId, (newVal) => {
            if (props.loadPicks) {
                setPlayerData({}, null)
                loadAuthPicks()
            }
        })

        const currentPicks = ref(null)

        const loadAuthPicks = function() {
            if (authEntry.value && activeGwId.value) {
                if (activeGw.value.number >= store.transfersGameweek.number) {
                    if (currentPicks.value === null) {
                        axios
                            .get('/api/entry/' + authEntry.value.id + '/gameweek-fixture-points/' + store.transfersGameweek.id)
                            .then(response => {
                                currentPicks.value = _.keyBy(response.data.picks, 'player_season_id')
                                setPlayerData(null, currentPicks.value)
                            })
                    } else {
                        setPlayerData(null, currentPicks.value)
                    }
                } else {
                    axios
                        .get('/api/entry/' + authEntry.value.id + '/gameweek-fixture-points/' + activeGwId.value)
                        .then(response => {
                            setPlayerData(response.data.player_fixtures, response.data.picks)
                        })
                }
            }
        }

        const activeFixtures = computed(() => {
            return _.groupBy(
                _.filter(
                    _.sortBy(store.activeFixtures, 'timestamp'),
                    fixture => {
                        return fixture.gameweek_id === activeGwId.value
                    }
                ),
                (fixture) => {
                    return dayjs.unix(fixture.timestamp).startOf('day').unix()
                }
            )
        })

        const maxGwNumber = computed(() => {
            const gwIds = _.map(store.activeFixtures, 'gameweek_id')
            return _.maxBy(_.filter(store.gameweeks, gw => gwIds.includes(gw.id)), 'number').number
        })

        const prettyDate = function(timestamp) {
            return dayjs.unix(timestamp).format('dddd MMMM D, YYYY')
        }

        const deadlineText = computed(() => {
            return dayjs(activeGw.value.locked_at).format('dddd MMMM D h:mm A')
        })

        return {activeFixtures, activeGw, activeGwId, maxGwNumber, prettyDate, deadlineText, playerData}
    }
}
</script>
