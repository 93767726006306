<template>
    <div id="join-league-form" class="box form">
        <h1>Join League</h1>
        <p>Got a league code? Enter the code below to join.</p>
        <br />
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="league_code">League Code</label>
                <input type="text" id="league_code"
                       placeholder="" required
                       v-model="code">
            </div>
            <div class="mb-3">
                <button type="submit" :disabled="!isValid">
                    Join League
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useSeasonStore} from "../stores/seasonStore";
import miniLeagueQuery from "../queries/miniLeagueQuery.js";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['authenticated', 'success'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const code = ref('')

        const isValid = computed(() => {
            return code.value.length === 8
        })

        const submitForm = () => {
            miniLeagueQuery.join(store.authEntry, code.value)
                .then(response => {
                    code.value = ''
                    useFlashStore().setFlash('success', 'League Joined')
                    emit('success', response)
                })
                .catch(error =>  {
                    useFlashStore().axiosError(error, 'Error resetting password')
                    emit('error', error)
                })
        }

        return {code, isValid, submitForm}
    }
}
</script>
