export default {
    async create(seasonId, teamId, platformUrl, name, position, value) {
        const response = await axios.post('/api/admin/player-seasons', {
            season_id: seasonId,
            team_id: teamId,
            platform_url: platformUrl,
            name: name,
            position: position,
            value: value
        })

        return response
    },
}
