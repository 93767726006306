<template>
    <nav id="header" :class="{open: showNav}">
        <h1>
            <router-link to="/">
                {{ title || defaultTitle }}
            </router-link>
        </h1>
        <button @click="showNav = !showNav" type="button">
            <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
        </button>
        <div id="top-nav" class="w-full xl:block xl:w-auto">
            <ul>
                <li>
                    <router-link :to="{name:'auth'}">
                        {{ authUser ? 'Home' : 'Login / Register' }}
                    </router-link>
                </li>
                <li>
                    <router-link :to="{name:'auth-entries'}">My Teams</router-link>
                </li>
                <slot></slot>
                <template v-if="authUser">
                    <li>
                        <router-link :to="{name:seasonId ? 'season-settings' : 'settings', params:{seasonId:seasonId }}">Account</router-link>
                    </li>
                </template>
                <li>
                    <a v-if="discordLink" :href="discordLink">Discord</a>
                    <router-link v-else :to="{name:'feedback'}">Feedback</router-link>
                </li>

            </ul>
        </div>
    </nav>
</template>
<script>
import { useDefaultStore } from '../stores/defaultStore'
import {computed, ref} from 'vue'

export default {
    props: {
        title: {
            type: String,
            required: false
        },
        seasonId: {
            type: Number,
            required: false
        }
    },
    setup() {
        const store = useDefaultStore()

        const authUser = computed(() => {
            return store.authUser;
        })

        const showNav = ref(false)

        const discordLink = computed(() => {
            return store.discordLink
        })

        const defaultTitle = computed(() => {
            return store.title
        })

        return {defaultTitle, authUser, showNav, discordLink}
    }
}
</script>
