<template>
    <tr v-if="!fixtures || fixtures.length === 0">
        <th v-if="showName" class="px-3">
            <div>
                <router-link :to="{name: 'player', params: {playerId: player.id}}">
                    {{ player.name }}
                </router-link>
                <template v-if="pick">
                    <i v-if="pick.is_captain" class="fa-solid fa-star ml-1 captain-icon" title="Captain"></i>
                    <i v-else-if="pick.is_vice_captain" class="fa-solid fa-star ml-1 vice-captain-icon" title="Vice Captain"></i>
                    <i v-if="pick.is_starter && pick.sub_id" class="fa-solid fa-arrow-up ml-1 text-green-500" title="Subbed On"></i>
                    <i v-else-if="pick.is_sub && pick.sub_id" class="fa-solid fa-arrow-down ml-1 text-red-500" title="Subbed Off"></i>
                </template>
            </div>

            <div class="small" style="font-weight: normal">
                <span>{{ player.position }}</span>
                <span class="ml-1">
                    <img v-if="team.image_path" :src="team.image_path" style="width:16px;display:inline" />
                    {{ team.short_name }}
                </span>
            </div>
        </th>
    </tr>
    <tr v-else v-for="(fixture, index) in fixtures">
        <th v-if="showName" class="px-3">
            <div>
                <router-link :to="{name: 'player', params: {playerId: player.id}}">
                    {{ player.name }}
                </router-link>
                <template v-if="pick">
                    <i v-if="pick.is_captain" class="fa-solid fa-star ml-1 captain-icon" title="Captain"></i>
                    <i v-else-if="pick.is_vice_captain" class="fa-solid fa-star ml-1 vice-captain-icon" title="Vice Captain"></i>
                    <i v-if="pick.is_starter && pick.sub_id" class="fa-solid fa-arrow-up ml-1 text-green-500" title="Subbed On"></i>
                    <i v-else-if="pick.is_sub && pick.sub_id" class="fa-solid fa-arrow-down ml-1 text-red-500" title="Subbed Off"></i>
                </template>
            </div>

            <div class="small" style="font-weight: normal">
                <span>{{ player.position }}</span>
                <span class="ml-1">
                    <img v-if="team.image_path" :src="team.image_path" style="width:16px;display:inline" />
                    {{ team.short_name }}
                </span>
            </div>
        </th>
        <td v-if="showGw && index === 0" :rowspan="fixtures.length">
            GW {{ gameweeks[fixture.gameweek_id].number }}
        </td>
        <td v-if="showDate">
            {{ fixture.date }}
        </td>
        <td v-if="showOpposition">
            <router-link :to="{name: 'fixture', params: {fixtureId: fixture.id}}">
                ({{ fixture.local_location }})
                {{ fixture.local_opponent.short_name}}
            </router-link>
        </td>
        <td v-if="showResult">
            <template v-if="fixture.result">
                {{ fixture.home_team_score }} - {{ fixture.away_team_score }}
                <span class="result-icon" :class="fixture.local_result">
                    {{ fixture.local_result }}
                </span>
            </template>
        </td>
        <td class="points-cell">
            <template v-if="stats[fixture.id].points">
                <template v-if="pick">
                    {{ stats[fixture.id].points * pick.multiplier }}
                    <small>({{ stats[fixture.id].points }})</small>
                </template>
                <template v-else>
                    {{ stats[fixture.id].points || 0 }}
                </template>
            </template>
            <template v-else>
                0
            </template>
        </td>
        <td>
            {{ stats[fixture.id].minutes || 0}}
            <i v-if="stats[fixture.id].subbed_on_minute" class="fa-solid fa-arrow-up ml-1 text-green-500" :title="'Subbed On ' + stats[fixture.id].subbed_on_minute"></i>
            <i v-if="stats[fixture.id].subbed_off_minute" class="fa-solid fa-arrow-down ml-1 text-red-500" :title="'Subbed Off ' + stats[fixture.id].subbed_off_minute"></i>
        </td>
        <td>
            {{ stats[fixture.id].goals_scored || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].assists || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].clean_sheets || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].goals_conceded || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].own_goals || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].penalties_scored || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].penalties_missed || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].penalties_won || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].penalties_conceded || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].penalties_saved || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].saves || 0 }}
        </td>
        <td>
            {{ (stats[fixture.id].clearances || 0) + (stats[fixture.id].blocks || 0) + (stats[fixture.id].interceptions || 0) }}
        </td>
        <td>
            {{ stats[fixture.id].duels_won || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].shots_on_target || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].key_passes || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].yellow_cards || 0 }}
        </td>
        <td>
            {{ stats[fixture.id].red_cards || 0 }}
        </td>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import dayjs from "dayjs";

export default {
    props: {
        gwId: {
            type: Number,
            required: true
        },
        fixtureId: {
            type: Number,
            required: false
        },
        playerId: {
            type: Number,
            required: true
        },
        teamId: {
            type: Number,
            required: true
        },
        pick: {
            type: Object,
            required: false
        },
        playerFixtures: {
            type: [Array, undefined],
            required: false
        },
        showName: {
            type: Boolean,
            default: true
        },
        showGw: {
            type: Boolean,
            default: true
        },
        showDate: {
            type: Boolean,
            default: true
        },
        showOpposition: {
            type: Boolean,
            default: true
        },
        showResult: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const store = useSeasonStore()

        const fixtures = computed(() => {
            return _.map(
                _.filter(_.orderBy(store.activeFixtures, 'timestamp', 'desc'), (fixture) => {
                    if (props.fixtureId) {
                        return fixture.id === props.fixtureId
                    } else {
                        return fixture.gameweek_id === props.gwId
                            && (fixture.home_team_id === props.teamId || fixture.away_team_id === props.teamId)
                    }
                }),
                fixture => {
                    return {
                        ...fixture,
                        date: dayjs.unix(fixture.timestamp).format('YYYY-MM-DD'),
                        local_location: (props.teamId === fixture.home_team_id) ? 'H' : 'A',
                        local_result: (props.teamId === fixture.home_team_id) ? fixture.home_result : fixture.away_result,
                        local_opponent: props.teamId === fixture.home_team_id
                            ? store.teams[fixture.away_team_id]
                            : store.teams[fixture.home_team_id]
                    }
                }
            )
        })

        const gameweeks = computed(() => {
            return store.gameweeks
        })

        const player = computed(() => {
            return store.players[props.playerId]
        })

        const team = computed(() => {
            return store.teams[props.teamId]
        })

        const stats = computed(() => {
            return _.mapValues(_.keyBy(fixtures.value, 'id'), fixture => {
                return _.find(props.playerFixtures, {fixture_id: fixture.id}) || {}
            })
        })

        const pickState = computed(() => {
            return _.get(props.pick, 'status', null)
        })

        return {fixtures, gameweeks, player, team, stats, pickState}
    }
}
</script>
