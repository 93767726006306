<template>
    <div id="player-list">
        <player-filter
            v-model="filter"
        ></player-filter>
        <table style="width:100%">
            <player-list-headers></player-list-headers>
            <tbody>
                <player-list-row
                    v-for="player in players"
                    :key="player.id"
                    :player="player">
                </player-list-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import PlayerListRow from "./PlayerListRow.vue";
import PlayerFilter from "./PlayerFilter.vue";
import PlayerListHeaders from "./PlayerListHeaders.vue";

export default {
    components: {PlayerListHeaders, PlayerFilter, PlayerListRow},
    setup(props, {emit}) {
        const store = useSeasonStore()

        const players = computed(() => {
            return store.filterPlayers(filter.value)
        })

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: 'value',
            sort_dir: 'desc',
            status: 'active',
        })

        return {players, filter}
    }
}
</script>
