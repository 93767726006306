<template>
    <div id="player-merge-form" class="box blue-gradient form">
        <div class="flex items-center">
            <h1>Edit</h1>
            <a :href="player.player.platform_url" target="_blank" class="ml-3">
                Sofascore / {{ player.player.platform_id }}
            </a>
        </div>
        <form @submit.prevent="submitForm">
            <template v-for="field in fields">
                <div v-if="field === 'name'" class="mb-3">
                    <label for="name">Name</label>
                    <input id="name"
                           type="text"
                           placeholder="Name"
                           required
                           v-model="attributes.name">
                </div>
                <div v-if="field === 'display_name'" class="mb-3">
                    <label for="display_name">Display Name</label>
                    <input id="display_name"
                           type="text"
                           placeholder="Display Name"
                           required
                           v-model="attributes.display_name">
                </div>
                <div v-if="field === 'status'" class="mb-3">
                    <label for="status">Status</label>
                    <button
                        v-for="(statusOptionLabel, statusOptionKey) in statusOptions" :key="statusOptionKey"
                        type="button"
                        class="btn"
                        :class="attributes.status === statusOptionKey ? 'selected' : 'inactive'"
                        @click.prevent="attributes.status = statusOptionKey">
                        {{ statusOptionLabel }}
                    </button>
                </div>
                <div v-if="field === 'mod_status'" class="mb-3">
                    <label for="modPending">
                        Require Moderation?
                        <span v-if="player.mod_status">({{ player.mod_status }})</span>
                    </label>
                    <button
                        v-for="modPendingOption in modPendingOptions" :key="modPendingOption.value"
                        type="button"
                        class="btn"
                        :class="attributes.mod_status === modPendingOption.value ? 'selected' : 'inactive'"
                        @click.prevent="attributes.mod_status = modPendingOption.value">
                        {{ modPendingOption.label }}
                    </button>
                </div>
                <div v-if="field === 'date_of_birth'" class="mb-3">
                    <label for="date_of_birth">Date of Birth - {{ player.age }}</label>
                    <input
                        id="date_of_birth"
                        v-model="attributes.date_of_birth"
                        type="date"
                    />
                </div>
                <div v-if="field === 'position'" class="mb-3">
                    <label for="position">Position</label>
                    <select
                        id="position"
                        v-model="attributes.position"
                        required
                    >
                        <option :value="null">Pick Position</option>
                        <option v-for="positionOption in positionOptions" :value="positionOption">{{ positionOption }}</option>
                    </select>
                </div>
                <div v-if="field === 'value'" class="mb-3">
                    <label for="value">Value</label>
                    <input type="number" id="value"
                           max="200"
                           v-model="attributes.value"
                           :disabled="season.budget === null && !attributes.value">
                </div>
                <div v-if="field === 'team_id'" class="mb-3">
                    <label for="team">Team</label>
                    <select
                        id="team"
                        v-model="attributes.team_id"
                        required
                    >
                        <option :value="null">Pick Team</option>
                        <option v-for="teamOption in teamOptions" :value="teamOption.id">{{ teamOption.name }}</option>
                    </select>
                </div>
            </template>
            <div class="my-3">
                <button :disabled="saving" class="success">
                    {{ saving ? 'Saving...' : 'Save' }}
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, onMounted, ref} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['success', 'error'],
    props: {
        player: {
            type: Object,
            required: true
        },
        fields: {
            type: Array,
            default: [
                'name',
                'display_name',
                'status',
                'mod_status',
                'date_of_birth',
                'position',
                'value',
                'team_id',
            ]
        }
    },
    setup(props, {emit}) {
        const seasonStore = useSeasonStore()

        const saving = ref(false)

        const season = computed(() => {
            return seasonStore.season
        })

        const statusOptions = ref({
            active: 'Active',
            inactive: 'Inactive',
            new: 'New',
            duplicate: 'Duplicate'
        })

        const modPendingOptions = ref([
            {value: null, label: 'No'},
            {value: 'pending', label: 'Yes'},
        ])

        const teamOptions = computed(() => {
            return _.sortBy(seasonStore.teams, 'name')
        })

        const positionOptions = ['GK', 'DEF', 'MID', 'ATT', 'Unknown']

        const attributes = ref({
            name: null,
            display_name: null,
            status: null,
            mod_status: null,
            date_of_birth: null,
            value: null,
            team_id: null,
            position: null
        })

        onMounted(() => {
            if (props.player) {
                attributes.value = {
                    ...attributes.value,
                    ..._.pick(props.player, _.keys(attributes.value)),
                }
            }
        })

        const submitForm = () => {

            saving.value = true



            seasonStore
                .updatePlayer(props.player.id, _.pick(attributes.value, props.fields))
                .then(response => {
                    emit('success')
                    useFlashStore().setFlash('success', 'Player Updated')
                })
                .catch(error => {
                    useFlashStore().axiosError(error, 'Error Saving Player')
                })
                .finally(() => {
                    saving.value = false
                })
        }

        return {saving, season, attributes, statusOptions, modPendingOptions, teamOptions, positionOptions, submitForm}
    }
}
</script>
