<template>
    <thead>
    <tr>
        <th class="hidden md:block"></th>
        <template v-for="column in columns">
            <th
                v-if="column.field !== 'value' || showValue"
                :class="{active: activeField === column.field}"
                :data-customTooltip="column.label"
                @click="$emit('selectColumn', column)"
            >
                {{ column.abbreviation }}
            </th>
        </template>
    </tr>
    </thead>
</template>
<script>

import columns from '@/config/PlayerColumns.js';
import {computed} from "vue";

export default {
    emits: ['selectColumn'],
    props: {
        showValue: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Object,
            required: false
        }
    },
    setup(props) {
        const activeField = computed(() => {
            return _.get(props.filter, 'sort')
        })

        return {columns, activeField}
    }
}
</script>
