<template>
    <div>
        <div class="flex items-center">
            <player-filter
                v-model="filter"
            ></player-filter>
            <button class="btn" @click.prevent="resetFilter">
                Clear
            </button>
            <router-link :to="{name: 'mod-create-player'}" class="btn">
                Add Player
            </router-link>
            <router-link :to="{name: 'mod-transfers'}" class="btn">
                Transfers
            </router-link>
        </div>
        <admin-player-table
            route-name="mod-player"
            :players="players"
            :columns="columns"
        ></admin-player-table>
    </div>
</template>

<script>
import {ref, computed} from "vue";
import {useSeasonStore} from "../stores/seasonStore.js";
import AdminPlayerTable from "@/components/AdminPlayerTable.vue";
import PlayerFilter from "@/components/PlayerFilter.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {PlayerFilter, AdminPlayerTable},
    setup() {
        const seasonStore = useSeasonStore();

        useSeoMeta({
            title: () => 'Mod | ' + seasonStore.season.name,
            description: () => 'Mod season ' + seasonStore.season.name
        })

        const players = computed(() => {
            return seasonStore.filterPlayers(filter.value)
        })

        const columns = [
            'player_id',
            'player_season_id',
            'name',
            'display_name',
            'team',
            'status_input',
            'selected_by',
            'position_input',
            'value_input',
            'points',
            'created_at',
        ];

        const resetFilter = () => {
            filter.value = {
                team_id: 'ALL',
                value: 'ALL',
                position: 'ALL',
            }
        }

        const filter = ref({
            team_id: 'ALL',
            value: 'ALL',
            position: 'ALL',
            sort: 'team',
            sort_dir: 'asc'
        })

        return {players, resetFilter, columns, filter}
    }
}
</script>
