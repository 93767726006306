
<template>
    <div class="fixture-row">
        <div class="home-team">
            {{ homeTeam.name}}
            <img v-if="homeTeam.image_path" :src="homeTeam.image_path" />
        </div>

        <router-link :to="{name: routeName, params: {fixtureId: fixture.id}}">
            <template v-if="fixture.status === 'NS'">
                <span class="kickoff">
                    {{ prettyTime }}
                </span>
            </template>
            <span v-else-if="fixture.status === 'postponed'" title="Postponed">
                <span class="home-team-score">
                    P
                </span>
                <span class="away-team-score">
                    P
                </span>
            </span>
            <template v-else>
                <span class="home-team-score">
                    {{ fixture.home_team_score }}
                </span>
                <span class="away-team-score">
                    {{ fixture.away_team_score }}
                </span>
            </template>
        </router-link>

        <div class="away-team">
            <img v-if="awayTeam.image_path" :src="awayTeam.image_path" />
            {{ awayTeam.name }}
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import dayjs from 'dayjs';

export default {
    props: {
        fixture: {
            type: Object,
            required: true
        },
        routeName: {
            type: String,
            default: 'fixture'
        }

    },
    setup(props) {
        const store = useSeasonStore()

        const homeTeam = computed(() => {
            return store.teams[props.fixture.home_team_id]
        })

        const awayTeam = computed(() => {
            return store.teams[props.fixture.away_team_id]
        })

        const prettyDate = dayjs(props.fixture.kickoff).format('dddd MMMM D, YYYY');
        const prettyTime = dayjs(props.fixture.kickoff).format('HH:mm')

        return {homeTeam, awayTeam, prettyDate, prettyTime}
    }
}
</script>
