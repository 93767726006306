<template>
    <transfers-list route-name="admin-player"></transfers-list>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref, onMounted} from "vue";
import TransferListRow from "@/components/TransferListRow.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import TransfersList from "@/components/TransfersList.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {TransfersList, SimplePagination, TransferListRow},
    setup(props) {
        useSeoMeta({
            title: () => 'God Transfers',
            description: () => 'God Transfers Page'
        })

        const store = useSeasonStore()

        onMounted(async () => {
            getPage(1)
        })

        const getPage = function (pageNum) {
            axios
                .get('/api/transfers/', {
                    params: {
                        page: pageNum
                    }
                })
                .then(response => {
                    transfers.value = response.data
                })
        }

        const updateTransfer = function (transfer, newData) {
            transfers.value.data = _.mergeInArray(transfers.value.data, {id: transfer.id}, newData)
        }

        const transfers = ref(null)

        const players = computed(() => {
            return _.pick(_.keyBy(store.players, 'player_id'), _.map(transfers.value.data, 'player_id'))
        })

        return {transfers, players, getPage, updateTransfer}
    }
}
</script>
