<template>
    <div id="points-pitch" class="box">
        <div class="pitch-container">
            <div class="pitch">
                <div v-for="groupId in groups" class="row" :class="groupId">
                    <picker-player
                        v-for="pick in groupedPlayers[groupId]"
                        :key="pick.id"
                        :player="pick.player"
                        :is-captain="pick.is_captain"
                        :is-vice-captain="pick.is_vice_captain"
                        :multiplier="pick.multiplier"
                        :is-sub="false"
                        :stats="playerGameweeks[pick.player_season_id]"
                        info-type="points"
                        :selectable="false"
                    ></picker-player>
                </div>
                <div class="row SUBS">
                    <picker-player
                        v-for="pick in groupedPlayers.SUB"
                        :key="pick.id"
                        :player="pick.player"
                        :is-captain="pick.is_captain"
                        :is-vice-captain="pick.is_vice_captain"
                        :multiplier="pick.multiplier"
                        :is-sub="false"
                        :stats="playerGameweeks[pick.player_season_id]"
                        info-type="points"
                        :selectable="false"
                    ></picker-player>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import PickerPlayer from "./PickerPlayer.vue";

export default {
    components: {PickerPlayer},
    props: {
        playerGameweeks: {
            type: Object,
            required: true
        },
        picks: {
            type: Object,
            required: true
        },
    },
    setup(props) {
        const store = useSeasonStore()

        const players = computed(() => {
            return _.map(props.picks, pick => {
                return {
                    ...pick,
                    player: store.players[pick.player_season_id]
                }
            })
        })

        const groups = ['GK', 'DEF', 'MID', 'ATT']
        const groupedPlayers = computed(() => {
            return _.groupBy(
                players.value,
                pick => {
                    return pick.is_sub ? 'SUB' : pick.player.position
                }
            )
        })

        return {groups, groupedPlayers}
    }
}
</script>
