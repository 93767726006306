<template>
    <div v-if="loaded">
        <season-nav-bar></season-nav-bar>
        <div v-if="season && season.status !== 'active'" class="box error">
            This season has finished. Visit the <router-link to="/"><strong style="text-decoration: underline">Home Page</strong></router-link> to see what competitions are currently active.
        </div>
        <router-view :key="seasonId"></router-view>
    </div>
</template>

<script>
import {onMounted, computed} from 'vue'
import {useSeasonStore} from "../stores/seasonStore";
import NavBar from "../components/NavBar.vue";
import SeasonNavBar from "../components/SeasonNavBar.vue";

export default {
    components: {SeasonNavBar, NavBar},
    props: {
        seasonId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const seasonStore = useSeasonStore()

        onMounted(() => {
            seasonStore.init(props.seasonId)
        })

        const season = computed(() => seasonStore.season)

        const loaded = computed(() => seasonStore.seasonLoaded)

        return {season, loaded}
    }
}
</script>
