<template>
    <div id="create-league-form" class="box form">
        <h1>Create League</h1>
        <p>Want to create a league? All you need is a name to get started, then you can invite your friends.</p>
        <br />
        <form @submit.prevent="submitForm">
            <div class="mb-3">
                <label for="league_name">Name</label>
                <input type="text" id="league_name"
                       placeholder="" required
                       v-model="name">
            </div>
            <div class="mb-3">
                <button type="submit" :disabled="!isValid">
                    Create League
                </button>
            </div>
        </form>
    </div>
</template>
<script>
import {computed, ref} from "vue";
import {useSeasonStore} from "../stores/seasonStore";
import miniLeagueQuery from "../queries/miniLeagueQuery.js";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['error', 'success'],
    setup(props, {emit}) {
        const store = useSeasonStore()

        const name = ref('')

        const isValid = computed(() => {
            return name.value.length >= '2'
        })

        const submitForm = () => {
            miniLeagueQuery
                .create(name.value, store.seasonId, [store.authEntry.id])
                .then(response => {
                    useFlashStore().setFlash('success', 'Mini League Created!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error creating mini league')
                })
        }

        return {name, isValid, submitForm}
    }
}
</script>
