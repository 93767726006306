<template>
    <div id="fixture-page">
        <fixture-header :fixture="fixture"></fixture-header>
        <div class="fixture-header">
            <div class="grid grid-cols-1 sm:grid-cols-2">
                <div class="justify-self-end">
                    <div v-for="groupId in groups" class="row" :class="groupId">
                        <template
                            v-for="player in groupedPlayers.home[groupId]"
                            :key="player.id">
                            <div class="text-right mr-3 mb-3"
                                 v-if="keyedPlayerFixtures[player.id].minutes > 0"
                            >
                                <div><small>({{ player.position }})</small> <strong>{{ player.name }}</strong> ({{ keyedPlayerFixtures[player.id].points }})</div>
                                <template v-for="(value, key) in keyedPlayerFixtures[player.id].points_calculation">
                                    <small v-if="value !== 0" class="ml-3">
                                        {{ key }}: {{ value }}
                                    </small>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <div>
                    <div v-for="groupId in groups" class="row" :class="groupId">
                        <template
                            v-for="player in groupedPlayers.away[groupId]"
                            :key="player.id"
                        >
                            <div class="text-left ml-3 mb-3"
                                 v-if="keyedPlayerFixtures[player.id].minutes > 0"
                            >
                                <div>({{ keyedPlayerFixtures[player.id].points }}) <strong>{{ player.name }}</strong> <small>({{ player.position }})</small></div>
                                <template v-for="(value, key) in keyedPlayerFixtures[player.id].points_calculation">
                                    <small v-if="value !== 0" class="mr-3">
                                        {{ key }}: {{ value }}
                                    </small>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <player-result-table
            class="mt-3"
            :player-gameweeks="playerGameweeks"
            :player-fixtures="playerFixtures"
            :fixture-id="fixture.id"
            :show-gw="false"
            :show-opposition="false"
            :show-result="false"
        ></player-result-table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, onMounted, ref} from "vue";
import dayjs from "dayjs";
import PlayerResultTable from "../components/PlayerResultTable.vue";
import PickerPlayer from "../components/PickerPlayer.vue";
import FixtureHeader from "../components/FixtureHeader.vue";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {FixtureHeader, PickerPlayer, PlayerResultTable},
    props: {
        fixtureId: {
            type: [String, Number],
            required: true
        }
    },
    setup(props) {
        const store = useSeasonStore()
        const fixture = computed(() => {
            return store.fixtures[props.fixtureId]
        })

        useSeoMeta({
            title: () => fixture.value.description + ' | Fantasy ' + store.season.name,
            description: () => fixture.value.description
        })

        onMounted(() => {
            axios
                .get('/api/fixture/' + props.fixtureId + '/stats')
                .then(response => {
                    playerFixtures.value = _.orderBy(response.data.playerFixtures, (playerFixture) => {
                        return store.players[playerFixture.player_season_id].name;
                    }, 'asc')
                    keyedPlayerFixtures.value = _.keyBy(response.data.playerFixtures, 'player_season_id')

                    playerGameweeks.value = _.sortBy(response.data.playerGameweeks, [
                        playerGameweek => {
                            return playerGameweek.team_id === homeTeam.value.id ? 1 : 2
                        },
                        playerGameweek => {
                            return keyedPlayerFixtures.value[playerGameweek.player_season_id].started ? 1 : 2
                        },
                        playerGameweek => {
                            return store.players[playerGameweek.player_season_id].position_sort;
                        }
                    ])
                })
        })

        const playerGameweeks = ref([])
        const playerFixtures = ref([])
        const keyedPlayerFixtures = ref({})

        const homeTeam = computed(() => {
            return store.teams[fixture.value.home_team_id]
        })

        const awayTeam = computed(() => {
            return store.teams[fixture.value.away_team_id]
        })

        const prettyDate = dayjs(fixture.value.kickoff).format('dddd MMMM D, YYYY');
        const prettyTime = dayjs(fixture.value.kickoff).format('HH:mm')

        const groups = ['GK', 'DEF', 'MID', 'ATT']
        const groupedPlayers = computed(() => {
            return {
                home: _.mapValues(
                    _.groupBy(_.filter(playerFixtures.value, {team_id: fixture.value.home_team_id}), playerFixture => {
                        return store.players[playerFixture.player_season_id].position
                    }),
                    group => _.pick(store.players, _.map(group, 'player_season_id'))
                ),
                away: _.mapValues(
                    _.groupBy(_.filter(playerFixtures.value, {team_id: fixture.value.away_team_id}), playerFixture => {
                        return store.players[playerFixture.player_season_id].position
                    }),
                    group => _.pick(store.players, _.map(group, 'player_season_id'))
                )
            }
        })

        return {fixture, keyedPlayerFixtures, playerGameweeks, playerFixtures, homeTeam, awayTeam, prettyDate, prettyTime, groups, groupedPlayers}
    }
}
</script>
