export default {
    name: {
        label: 'Name',
        abbreviation: 'Name',
        field: 'name',
        description: 'The players name',
        sortable: true,
    },
    value: {
        label: 'Value',
        abbreviation: 'Value',
        field: 'value',
        description: 'How much the player costs',
        sortable: true,
    },
    team: {
        label: 'Team',
        abbreviation: 'Team',
        field: 'team',
        description: 'The name of the team',
        sortable: true,
    },
    selected_by: {
        label: 'Selected By Percentage',
        abbreviation: 'Sel %',
        field: 'selected_by_percent',
        description: 'The percentage of teams that own this player',
        sortable: true,
        suffix: ' %'
    },
    points: {
        label: 'Points',
        abbreviation: 'Pts',
        field: 'points',
        description: 'How many points the player scored',
        sortable: true,
        suffix: ' Pts'
    },
    points_per_game: {
        label: 'Points Per Game',
        abbreviation: 'PPG',
        field: 'points_per_game',
        description: "Average points per game the players team played even if they didn't feature",
        sortable: true,
    },
    points_per_appearance: {
        label: 'Points Per Appearance',
        abbreviation: 'PPA',
        field: 'points_per_appearance',
        description: 'Average points per appearance',
        sortable: true,
    },
    points_per_ninety: {
        label: 'Points Per 90 Minutes',
        abbreviation: 'PP90',
        field: 'points_per_ninety',
        description: 'Average points per 90 minutes',
        sortable: true,
    },
    points_per_start: {
        label: 'Points Per Start',
        abbreviation: 'PPSt',
        field: 'points_per_start',
        description: 'Average points per start',
        sortable: true,
    },
    appearances: {
        label: 'Appearances',
        abbreviation: 'APP',
        field: 'appearances',
        description: 'Total number of appearances',
        sortable: true,
    },
    starts: {
        label: 'Starts',
        abbreviation: 'St',
        field: 'starts',
        description: 'Total number of starts',
        sortable: true,
    },
    minutes: {
        label: 'Minutes',
        abbreviation: 'MIN',
        field: 'minutes',
        description: 'Total minutes played',
        sortable: true,
    },
    goals_scored: {
        label: 'Goals',
        abbreviation: 'GS',
        field: 'goals_scored',
        description: 'Total goals scored',
        sortable: true,
    },
    assists: {
        label: 'Assists',
        abbreviation: 'A',
        field: 'assists',
        description: 'Total assists made',
        sortable: true,
    },
    clean_sheets: {
        label: 'Clean Sheets',
        abbreviation: 'CS',
        field: 'clean_sheets',
        description: 'Total clean sheets',
        sortable: true,
    },
    conceded: {
        label: 'Goals Conceded',
        abbreviation: 'GC',
        field: 'conceded',
        description: 'Total goals conceded',
        sortable: true,
    },
    own_goals: {
        label: 'Own Goals',
        abbreviation: 'OG',
        field: 'own_goals',
        description: 'Total own goals scored',
        sortable: true,
    },
    penalties_scored: {
        label: 'Penalties Scored',
        abbreviation: 'PGS',
        field: 'penalties_scored',
        description: 'Total penalties scored',
        sortable: true,
    },
    penalties_missed: {
        label: 'Penalties Missed',
        abbreviation: 'PM',
        field: 'penalties_missed',
        description: 'Total penalties missed',
        sortable: true,
    },
    penalties_won: {
        label: 'Penalties Won',
        abbreviation: 'PW',
        field: 'penalties_won',
        description: 'Total penalties won',
        sortable: true,
    },
    penalties_conceded: {
        label: 'Penalties Conceded',
        abbreviation: 'PC',
        field: 'penalties_conceded',
        description: 'Total penalties conceded',
        sortable: true,
    },
    penalties_savd: {
        label: 'Penalties Saved',
        abbreviation: 'PS',
        field: 'penalties_saved',
        description: 'Total penalties saved',
        sortable: true,
    },
    saves: {
        label: 'Saves',
        abbreviation: 'S',
        field: 'saves',
        description: 'Total saves made',
        sortable: true,
    },
    clearances_blocks_interceptions: {
        label: 'Clearances, Blocks & Interceptions',
        abbreviation: 'CBI',
        field: 'cbi',
        description: 'Total clearances, blocks, and interceptions',
        sortable: true,
    },
    duels_won: {
        label: 'Duels Won',
        abbreviation: 'DW',
        field: 'duels_won',
        description: 'Total duels won',
        sortable: true,
    },
    shots_on_target: {
        label: 'Shots on Target',
        abbreviation: 'SOT',
        field: 'shots_on_target',
        description: 'Total shots on target',
        sortable: true,
    },
    key_passes: {
        label: 'Key Passes',
        abbreviation: 'KP',
        field: 'key_passes',
        description: 'Total key passes made',
        sortable: true,
    },
    yellows: {
        label: 'Yellow Cards',
        abbreviation: 'YC',
        field: 'yellows',
        description: 'Total yellow cards received',
        sortable: true,
    },
    reds: {
        label: 'Red Cards',
        abbreviation: 'RC',
        field: 'reds',
        description: 'Total red cards received',
        sortable: true,
    },
};
