<template>
    <tr>
        <td>
            <player-status-select
                v-if="player"
                :auto-save="true"
                :player="player"
            ></player-status-select>
            <template v-else v-for="(playerSeasonId, seasonId) in transfer.season_ids">
                <div v-if="seasons.hasOwnProperty(seasonId)">
                    <router-link class="hover-underline" :to="{name: routeName, params: {seasonId: seasonId, playerId: playerSeasonId}}">
                        {{ seasons[seasonId].name }}
                    </router-link>
                </div>
            </template>
        </td>
        <td>
            <router-link v-if="player" :to="{name: routeName, params: {seasonId: player.season_id, playerId: player.id}}">
                {{ player.name }}
            </router-link>
            <template v-else>
                {{ transfer.player_name }}
            </template>
        </td>
        <td>
            <span v-if="team" :class="{'text-red-500':team.id !== transfer.to_id && player && player.status === 'active'}">
                {{ team.name }}
            </span>
        </td>
        <td>
            {{ transfer.date }}
        </td>
        <td>
            {{ transfer.from_name }}
        </td>
        <td>
            {{ transfer.to_name }}
            <div v-if="toTeamIsSameSeason && team.id !== transfer.to_id">
                <a href="#" @click.prevent="transferToTeam()" class="text-sm text-green-500">
                    Transfer to team
                </a>
            </div>
        </td>
        <td>
            {{ transfer.transfer_fee_description }}
        </td>
        <td>
            <transfer-status-select
                :auto-save="true"
                :transfer="transfer"
                @success="$emit('saved', $event)"
            ></transfer-status-select>
        </td>
        <td>
            <a v-if="player" target="_blank" :href="player.player.platform_url">
                Source
            </a>
        </td>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import {useDefaultStore} from "@/stores/defaultStore";
import PlayerStatusSelect from "@/components/PlayerStatusSelect.vue";
import TransferStatusSelect from "@/components/TransferStatusSelect.vue";
import {useFlashStore} from "@/stores/flashStore";

export default {
    emits: ['saved', 'playerUpdated', 'error'],
    components: {TransferStatusSelect, PlayerStatusSelect},
    props: {
        transfer: {
            type: Object,
            required: true
        },
        routeName: {
            type: String,
            required: true
        }
    },
    setup(props, {emit}) {
        const defaultStore = useDefaultStore()
        const seasonStore = useSeasonStore()

        const player = computed(() => {
            return _.find(seasonStore.players, {player_id: props.transfer.player_id})
        })

        const team = computed(() => {
            return player.value
                ? seasonStore.teams[player.value.team_id]
                : null
        })

        const toTeamIsSameSeason = computed(() => {
            return props.transfer.to_id && seasonStore.teams.hasOwnProperty(props.transfer.to_id)
        })

        const transferToTeam = function() {
            seasonStore
                .updatePlayer(player.value.id, {team_id: props.transfer.to_id})
                .then(response => {
                    useFlashStore().setFlash('success', 'Played Transferred!')
                    emit('success', response)
                })
                .catch(error =>  {
                    emit('error', error)
                    useFlashStore().axiosError(error, 'Error transferring player')
                })
        }

        const seasons = computed(() => {
            return _.pickBy(_.pick(defaultStore.seasons, _.keys(props.transfer.season_ids)), {status:'active'})
        })

        return {player, team, toTeamIsSameSeason, transferToTeam, seasons}
    }
}
</script>
