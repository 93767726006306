<template>
    <div class="stats-table relative overflow-x-auto">
        <table class="w-full">
            <thead>
                <tr>
                    <th v-if="seasons">
                        Season
                    </th>
                    <th>
                        Points
                    </th>
                    <th>
                        App
                    </th>
                    <th>
                        Minutes
                    </th>
                    <th>
                        Goals
                    </th>
                    <th>
                        Assists
                    </th>
                    <th>
                        Clean Sheets
                    </th>
                    <th>
                        Conceded
                    </th>
                    <th>
                        Own Goals
                    </th>
                    <th>
                        Pens Scored
                    </th>
                    <th>
                        Pens Missed
                    </th>
                    <th>
                        Pens Won
                    </th>
                    <th>
                        Pens Con
                    </th>
                    <th>
                        Pens Saved
                    </th>
                    <th>
                        Saves
                    </th>
                    <th>CBI</th>
                    <th>Duels Won</th>
                    <th>Shots on Target</th>
                    <th>Key Passes</th>
                    <th>
                        Yellows
                    </th>
                    <th>
                        Reds
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(playerSeason, seasonId) in playerSeasons">
                    <th v-if="seasons" class="text-left">
                        {{ seasons[seasonId].name }}
                    </th>
                    <td>
                        {{ playerSeason.points }}
                    </td>
                    <td>
                        {{ playerSeason.appearances }}
                    </td>
                    <td>
                        {{ playerSeason.minutes }}
                    </td>
                    <td>
                        {{ playerSeason.goals_scored }}
                    </td>
                    <td>
                        {{ playerSeason.assists }}
                    </td>
                    <td>
                        {{ playerSeason.clean_sheets || 0 }}
                    </td>
                    <td>
                        {{ playerSeason.goals_conceded || 0 }}
                    </td>
                    <td>
                        {{ playerSeason.own_goals || 0}}
                    </td>
                    <td>
                        {{ playerSeason.penalties_scored }}
                    </td>
                    <td>
                        {{ playerSeason.penalties_missed }}
                    </td>
                    <td>
                        {{ playerSeason.penalties_won }}
                    </td>
                    <td>
                        {{ playerSeason.penalties_conceded }}
                    </td>
                    <td>
                        {{ playerSeason.penalties_saved || 0}}
                    </td>
                    <td>
                        {{ playerSeason.saves }}
                    </td>
                    <td>
                        {{ (playerSeason.clearances || 0) + (playerSeason.blocks || 0) + (playerSeason.interceptions || 0) }}
                    </td>
                    <td>
                        {{ playerSeason.duels_won || 0 }}
                    </td>
                    <td>
                        {{ playerSeason.shots_on_target || 0 }}
                    </td>
                    <td>
                        {{ playerSeason.key_passes || 0 }}
                    </td>
                    <td>
                        {{ playerSeason.yellow_cards }}
                    </td>
                    <td>
                        {{ playerSeason.red_cards }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        seasons: {
            type: Object,
            required: false
        },
        playerSeasons: {
            type: Object,
            required: true
        }
    },
}
</script>
