<template>
    <div class="stats-table relative overflow-x-auto">
        <table class="w-full">
            <thead>
            <tr>
                <th v-if="showName">Name</th>
                <th v-if="showGw">GW</th>
                <th v-if="showDate">GW</th>
                <th v-if="showOpposition">Opposition</th>
                <th v-if="showResult">Score</th>
                <th>Point</th>
                <th>
                    Min
                </th>
                <th>
                    Gls
                </th>
                <th>
                    Ass
                </th>
                <th>
                    ClS
                </th>
                <th>
                    Con
                </th>
                <th>
                    OG
                </th>
                <th>
                    PGoa
                </th>
                <th>
                    PMis
                </th>
                <th>
                    PWon
                </th>
                <th>
                    PCon
                </th>
                <th>
                    PenS
                </th>
                <th>
                    Sav
                </th>
                <th>
                    CBI
                </th>
                <th>
                    DuelW
                </th>
                <th>
                    SOT
                </th>
                <th>
                    KPass
                </th>
                <th>
                    Yel
                </th>
                <th>
                    Red
                </th>
            </tr>
            </thead>
            <tbody>
                <player-result-table-row
                    v-for="playerGameweek in playerGameweeks" :key="playerGameweek.id"
                    :team-id="playerGameweek.team_id"
                    :gw-id="playerGameweek.gameweek_id"
                    :fixture-id="fixtureId"
                    :player-id="playerGameweek.player_season_id"
                    :pick="picks[playerGameweek.player_season_id]"
                    :player-fixtures="groupedPlayerFixtures[playerGameweek.player_season_id]"
                    :show-name="showName"
                    :show-gw="showGw"
                    :show-date="showDate"
                    :show-opposition="showOpposition"
                    :show-result="showResult"
                ></player-result-table-row>
            </tbody>
        </table>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed} from "vue";
import PlayerResultTableRow from "./PlayerResultTableRow.vue";

export default {
    components: {PlayerResultTableRow},
    props: {
        playerGameweeks: {
            type: Array,
            required: true
        },
        playerFixtures: {
            type: Array,
            required: false
        },
        fixtureId: {
            // Only used when showing a single fixture
            type: Number,
            required: false
        },
        picks: {
            type: Object,
            default: () => ({})
        },
        showName: {
            type: Boolean,
            default: true
        },
        showGw: {
            type: Boolean,
            default: true
        },
        showDate: {
            type: Boolean,
            default: true
        },
        showOpposition: {
            type: Boolean,
            default: true
        },
        showResult: {
            type: Boolean,
            default: true
        }
    },
    setup(props) {
        const groupedPlayerFixtures = computed(() => {
            return _.groupBy(props.playerFixtures, 'player_season_id')
        })

        return {groupedPlayerFixtures}
    }
}
</script>
