<template>
    <div v-if="rows" class="box">
        <h3>Historical Stats</h3>
        <player-historical-stats
            :rows="rows">
        </player-historical-stats>
    </div>
</template>

<script>
import {ref, onMounted} from "vue";
import PlayerHistoricalStats from "@/components/PlayerHistoricalStats.vue";

export default {
    components: {PlayerHistoricalStats},
    props: {
        playerId: {
            type: [Number, String],
            required: true
        }
    },
    emits: ['close'],
    setup(props) {
        onMounted(() => {
            axios
                .get('/api/players/' + props.playerId + '/historical-stats')
                .then(response => {
                    rows.value = response.data.stats
                })
        })

        const rows = ref(undefined)

        return {rows}
    }
}
</script>
