<template>
    <div class="stats-table relative overflow-x-auto">
        <table class="w-full">
            <thead>
            <tr>
                <th>
                    Season
                </th>
                <th>
                    Points
                </th>
                <th>
                    App
                </th>
                <th>
                    Minutes
                </th>
                <th>
                    Goals
                </th>
                <th>
                    Assists
                </th>
                <th>
                    Clean Sheets
                </th>
                <th>
                    Conceded
                </th>
                <th>
                    Own Goals
                </th>
                <th>
                    Pens Saved
                </th>
                <th>
                    Pens Scored
                </th>
                <th>
                    Pens Missed
                </th>
                <th>
                    Pens Won
                </th>
                <th>
                    Pens Con
                </th>
                <th>
                    Yellows
                </th>
                <th>
                    Reds
                </th>
                <th>CBI</th>
                <th>Duels Won</th>
                <th>Shots on Target</th>
                <th>Key Passes</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="stats in rows">
                <th class="text-left">
                    {{ stats.season_name }}
                </th>
                <td>
                    {{ stats.points || 0 }}
                </td>
                <td>
                    {{ stats.appearances || 0 }}
                </td>
                <td>
                    {{ stats.minutes || 0 }}
                </td>
                <td>
                    {{ stats.goals_scored || 0 }}
                </td>
                <td>
                    {{ stats.assists || 0 }}
                </td>
                <td>
                    {{ stats.clean_sheets || 0 }}
                </td>
                <td>
                    {{ stats.goals_conceded || 0 }}
                </td>
                <td>
                    {{ stats.own_goals || 0}}
                </td>
                <td>
                    {{ stats.penalties_saved || 0}}
                </td>
                <td>
                    {{ stats.penalties_scored || 0 }}
                </td>
                <td>
                    {{ stats.penalties_missed || 0 }}
                </td>
                <td>
                    {{ stats.penalties_won || 0 }}
                </td>
                <td>
                    {{ stats.penalties_conceded || 0 }}
                </td>
                <td>
                    {{ stats.yellow_cards || 0 }}
                </td>
                <td>
                    {{ stats.red_cards || 0 }}
                </td>
                <td>
                    {{ stats.red_cards || 0 }}
                </td>
                <td>
                    {{ (stats.clearances || 0) + (stats.blocks || 0) + (stats.interceptions || 0) }}
                </td>
                <td>
                    {{ stats.duels_won || 0 }}
                </td>
                <td>
                    {{ stats.shots_on_target || 0 }}
                </td>
                <td>
                    {{ stats.key_passes || 0 }}
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    props: {
        rows: {
            type: Array,
            required: true
        }
    },
}
</script>
