<template>
    <div v-if="transfers && transfers.data.length > 0">
        <slot name="header"></slot>
        <div v-if="!playerId" class="pagination-controls blue-gradient p-2 rounded flex items-center">
            <simple-pagination :collection="transfers" @goTo="getPage($event)"></simple-pagination>
            <div class="ml-3">
                <select v-model="statusValue">
                    <option :value="null">All</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Complete</option>
                </select>
            </div>
        </div>
        <div v-if="transfers" class="transfers-table stats-table">
            <table class="w-full">
                <thead>
                <tr>
                    <th>
                        Current Status
                    </th>
                    <th>
                        Player
                    </th>
                    <th>
                        Current Team
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        From
                    </th>
                    <th>
                        To
                    </th>
                    <th>
                        Fee
                    </th>
                    <th>
                        Transfer Status
                    </th>
                    <th>
                        Source
                    </th>
                </tr>
                </thead>
                <tbody>
                <transfer-list-row
                    v-for="transfer in transfers.data"
                    :route-name="routeName"
                    :transfer="transfer"
                    @saved="updateTransfer(transfer, $event)"
                ></transfer-list-row>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref, onMounted, watch} from "vue";
import TransferListRow from "@/components/TransferListRow.vue";
import SimplePagination from "@/components/SimplePagination.vue";
import router from "@/routes";

export default {
    components: {SimplePagination, TransferListRow},
    props: {
        seasonId: {
            type: [Number, String],
            default: null
        },
        routeName: {
            type: String,
            required: true
        },
        status: {
            type: String,
            default: null
        },
        playerId: {
            type: Number,
            default: null
        }
    },
    setup(props) {
        onMounted(async () => {
            getPage(1)
        })

        const statusValue = ref(props.status)

        watch(statusValue, (newValue, oldValue) => {
            getPage(1)
        })

        const getPage = function (pageNum) {
            router.replace({ params: { status: statusValue.value } })


            axios
                .get('/api/transfers/', {
                    params: {
                        season_id: props.seasonId,
                        status: statusValue.value,
                        player_id: props.playerId,
                        page: pageNum
                    }
                })
                .then(response => {
                    transfers.value = response.data
                })
        }

        const updateTransfer = function (transfer, newData) {
            transfers.value.data = _.mergeInArray(transfers.value.data, {id: transfer.id}, newData)
        }

        const transfers = ref(null)

        return {statusValue, transfers, getPage, updateTransfer}
    }
}
</script>
