<template>
    <div id="auth-page">
        <div v-if="authUser">
            <div class="p-3">You are logged in!</div>
            <div class="mb-3">
                <router-link :to="{name:'home'}" class="btn success">
                    Open App
                </router-link>
            </div>
        </div>
        <div v-else>
            <beta-text></beta-text>
            <div class="grid grid-cols-1 md:grid-cols-2">
                <div class="flex-grow order-2 md:order-1">
                    <login-form @authenticated="afterAuth"></login-form>
                    <lost-password-form></lost-password-form>
                </div>
                <div class="flex-grow order-1 md:order-2">
                    <register-form @authenticated="afterAuth"></register-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import LoginForm from "../components/LoginForm.vue";
import RegisterForm from "../components/RegisterForm.vue";

import {computed, onMounted, ref} from 'vue'
import {useRouter} from "vue-router";
import BetaText from "../components/BetaText.vue";
import LostPasswordForm from "../components/LostPasswordForm.vue";
import {useDefaultStore} from "../stores/defaultStore";
import {useSeoMeta} from "@unhead/vue";

export default {
    components: {LostPasswordForm, BetaText, LoginForm, RegisterForm},
    setup() {
        useSeoMeta({
            title: 'Login or Register',
            description: 'Authenticate with the World Fantasy Soccer app.'
        })
        const store = useDefaultStore()
        const router = useRouter()

        const afterAuth = () => {
            router.push({ name: 'home' })
        }

        const authUser = computed(() => {
            return store.authUser;
        })

        onMounted(() => {
            if (store.authUser) {
                afterAuth()
            }
        })

        return {afterAuth, authUser}
    }
}
</script>
