import {defineStore} from 'pinia'

export const useFlashStore = defineStore('flashStore', {
    state: () => ({
        message: null,
        state: null
    }),
    actions: {
        setFlash(state, message) {
            this.state = state
            this.message = message
        },
        axiosError(error, defaultMessage) {
            this.state = 'error'
            this.message = _.get(error, 'response.data.message') || defaultMessage
        },
        clear() {
            this.message = null
            this.state = null
        }
    }
})
