import {defineStore} from 'pinia'

export const useSeasonStore = defineStore('seasonStore', {
    state: () => ({
        seasonLoaded: false,
        season: undefined,
        seasonId: undefined,
        authEntry: undefined,
        authCanMod: false,
        transfersGwId: undefined,
        pointsGwId: undefined,
        chips: undefined,
        authChips: undefined,
        teams: undefined,
        gameweeks: {},
        players: undefined,
        fixtures: undefined,
        stats: {
            value: 'Value',
            position_id: 'Position',
            points: 'Points',
            points_per_game: 'Points Per Game',
            selected_by: 'Selected By %',
            minutes: 'Minutes',
            goals_scored: 'Goals Scored',
            assists: 'Assists',
            clean_sheets: 'Clean Sheets',
            goals_conceded: 'Goals Conceded',
            own_goals: 'Own Goals',
            penalties_saved: 'Pens Saved',
            penalties_scored: 'Pens Scored',
            penalties_missed: 'Pens Missed',
            penalties_won: 'Pens Won',
            penalties_conceded: 'Pens Con',
            yellow_cards: 'Yellow Cards',
            red_cards: 'Red Cards',
            saves: 'Saves',
            bonus: 'Bonus'
        }
    }),
    getters: {
        gameweekIds: (state) => _.map(_.sortBy(state.gameweeks, 'number'), 'id'),
        transfersGameweek: (state) => state.gameweeks[state.transfersGwId],
        pointsGameweek: (state) => state.gameweeks[state.pointsGwId],
        usePreviousSeasonId: (state) => state.season.previous_season_id && state.transfersGameweek && state.transfersGameweek.start_at_timestamp > Math.floor(Date.now() / 1000) ? state.season.previous_season_id : null,
        activeFixtures: (state) => _.filter(state.fixtures, fixture => fixture.status !== 'postponed'),
        fixturesGwId: (state) => {
            if (!state.pointsGwId) {
                return state.transfersGwId
            }

            if (state.pointsGameweek.status !== 'finished' || !state.transfersGwId) {
                return state.pointsGameweek.id
            }

            const timestamp = Math.floor(Date.now() / 1000);

            console.log(
                Math.abs(timestamp - state.pointsGameweek.end_at_timestamp),
                Math.abs(timestamp - state.transfersGameweek.start_at_timestamp)
            )

            return Math.abs(timestamp - state.pointsGameweek.end_at_timestamp) > Math.abs(timestamp - state.transfersGameweek.start_at_timestamp)
                ? state.transfersGwId
                : state.pointsGwId
        },
        getTeamFixtures: (state) => (teamId) => _.filter(state.fixtures, fixture => {
            return (fixture.home_team_id === teamId || fixture.away_team_id === teamId)
                && fixture.status === 'NS'
        }),
        getTeamResults: (state) => (teamId) => _.filter(state.fixtures, fixture => {
            return (fixture.home_team_id === teamId || fixture.away_team_id === teamId)
                && fixture.status === 'FT'
        }),
        getGwOpposition: (state) => (teamId, gwId) => _.map(_.filter(state.activeFixtures, fixture => {
            return (fixture.home_team_id === teamId || fixture.away_team_id === teamId)
                && fixture.gameweek_id === gwId
        }), fixture => {
            return {
                team: state.teams[fixture.home_team_id === teamId ? fixture.away_team_id : fixture.home_team_id],
                location: fixture.home_team_id === teamId ? 'H' : 'A'
            }
        }),
        filterPlayers: (state) => (filter) => {
            return state.sortPlayers(_.filter(state.players,player => {
                if (filter.status && player.status != filter.status) {
                    return false
                }

                if (filter.position !== 'ALL' && player.position !== filter.position) {
                    return false
                }

                if (filter.value && filter.value !== 'ALL') {
                    if (filter.value === 'AFFORDABLE' && filter.affordable_value) {
                        if (player.value > filter.affordable_value) {
                            return false
                        }
                    } else {
                        if (player.value > filter.value) {
                            return false
                        }
                    }
                }

                if (filter.team_id !=='ALL') {
                    if (Array.isArray(filter.team_id)) {
                        return filter.team_id.includes(player.team_id)
                    } else {
                        return player.team_id === parseInt(filter.team_id)
                    }
                }

                return true
            }),filter.sort, filter.sort_dir)
        },
        sortPlayers: (state) => (players, sortKey, sortDir) => {
            return _.orderBy(players, [player => {
                switch(sortKey) {
                    case 'name':
                        return player.second_name
                    case 'position':
                        return {
                            'GK': 1,
                            'DEF' : 2,
                            'MID': 3,
                            'ATT': 4
                        }[player.position] || 0
                    case 'team':
                        return state.teams[player.team_id].name
                    case 'value':
                    case 'points':
                    case 'points_per_game':
                    case 'selected_by':
                    case 'points_per_start':
                    default:
                        return player[sortKey] || 0
                }
            }, 'value'], [sortDir, 'desc'])
        }
    },
    actions: {
        reset() {
            Object.keys(this.$state).forEach(key => {
                this.$state[key] = null; // Or set to default values if needed
            });
        },
        async init(seasonId) {
            this.seasonLoaded = false
            /*
            await axios.get('/sanctum/csrf-cookie').then(response => {
                console.log('done the cookie thing')
            });
            */

            try {
                const response = await axios.get('/api/init/season/' + seasonId)
                this.season = response.data.season
                this.seasonId = response.data.season.id
                this.authEntry = response.data.auth_entry
                this.authCanMod = response.data.auth_can_mod
                this.chips = _.keyBy(response.data.chips, 'id')
                this.authChips = response.data.auth_chips
                this.transfersGwId = response.data.transfers_gw_id
                this.pointsGwId = response.data.points_gw_id
                this.players = _.keyBy(response.data.players, 'id')
                this.teams = _.keyBy(response.data.teams, 'id')
                this.gameweeks = _.keyBy(response.data.gameweeks, 'id')
                this.fixtures = _.keyBy(response.data.fixtures, 'id')
                this.seasonLoaded = true
            } catch (error) {
                console.log(error)
            }
        },
        setAuthEntry(entry) {
            this.authEntry = entry
        },
        async createEntry(teamName) {
            const response = await axios.post('/api/entry', {
                team_name: teamName,
                season_id : this.seasonId
            })

            this.authEntry = response.data.entry
        },
        async updateEntry(payload) {
            const response = await axios.patch('/api/entry/' + this.authEntry.id, payload)

            this.authEntry = response.data.entry
        },
        async updatePlayer(playerId, attributes) {
            const response = await axios.patch('/api/admin/player-seasons/' + playerId, attributes)

            console.log(response.data.player_season)

            this.players[playerId] = {
                ...this.players[playerId],
                ...response.data.player_season
            }

            return response
        },
        async mergePlayer(duplicatePlayerId, otherPlayerId) {
            const response = await axios.patch('/api/admin/merge-players/' + duplicatePlayerId + '/' + otherPlayerId + '/' + this.seasonId)

            if (response && response.hasOwnProperty('player_seasons')) {
                response.player_seasons.each(player_season => {
                    this.players[player_season.id] = {
                        ...this.players[player_season.id],
                        ...player_season
                    }
                })
            }

            return response
        }
    },
})
