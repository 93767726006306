<template>
    <list-all-players></list-all-players>
</template>

<script>
import ListAllPlayers from "../components/ListAllPlayers.vue";
import {useSeoMeta} from "@unhead/vue";
import {useSeasonStore} from "@/stores/seasonStore";

export default {
    components: {ListAllPlayers},
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'All Players | Fantasy ' + seasonStore.season.name,
            description: () => 'All Players in ' + seasonStore.season.name
        })
    }
}
</script>
