<template>
    <router-view v-if="canMod"></router-view>
</template>
<script>
import {computed} from "vue";
import {useSeasonStore} from "@/stores/seasonStore";
import {useSeoMeta} from "@unhead/vue";

export default {
    setup() {
        const seasonStore = useSeasonStore()

        useSeoMeta({
            title: () => 'Mod | ' + seasonStore.season.name,
            description: () => 'Mod area'
        })

        const canMod = computed(() => {
            return seasonStore.authCanMod
        })

        return {canMod}
    }
}
</script>
