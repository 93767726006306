<template>
    <modal v-if="showModal" @close="showModal = false">
        <template #body>
            <div id="player-modal">
                <player-profile :player="player"></player-profile>
            </div>
        </template>
    </modal>
    <tr :class="{selected: isSelected, picked: isPicked, fade: fade}">
        <td class="hidden md:block">
            <button @click="showModal = true">
                <i class="fa-solid fa-info"></i>
            </button>
        </td>
        <template v-for="column in columns">
            <td v-if="column.field === 'name'" class="name" @click="$emit('selectPlayer')" :title="player.name">
                {{ player.name }}
                <strong>({{ player.position }})</strong>
            </td>
            <template v-else-if="column.field === 'value'">
                <td v-if="showValue">
                    $ {{ player.pretty_value }}
                </td>
            </template>
            <td v-else-if="column.field === 'team'">
                <div class="team">
                    <div class="logo">
                        <img :src="team.image_path" />
                    </div>
                    <small>{{ team.short_name }}</small>
                </div>
            </td>
            <td v-else-if="column.field === 'selected_by_percent'">
                {{ formattedSelectedBy }}%
            </td>
            <td v-else>
                {{ (player[column.field] || 0) }} {{ column.suffix }}
            </td>
        </template>
    </tr>
</template>

<script>
import {useSeasonStore} from "../stores/seasonStore";
import {computed, ref} from "vue";
import Modal from "./Modal.vue";
import PlayerProfile from "./PlayerProfile.vue";
import columns from '@/config/PlayerColumns.js';

export default {
    components: {PlayerProfile, Modal},
    emits: ['selectPlayer'],
    props: {
        player: {
            type: Object,
            required: true
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        isPicked: {
            type: Boolean,
            default: false
        },
        fade: {
            type: Boolean,
            default: false
        },
        showValue: {
            type: Boolean,
            default: true
        },
        filter: {
            type: Object,
            required: false
        }
    },
    setup(props, {emit}) {
        const store = useSeasonStore()

        const team = computed(() => {
            return store.teams[props.player.team_id] || {name: props.player.team_id}
        })

        const formattedSelectedBy = computed(() => {
            return (props.player.selected_by_percent || 0).toFixed(0)
        })

        const activeField = computed(() => {
            return _.get(props.filter, 'sort')
        })

        const showModal = ref(false)

        return {columns, team, formattedSelectedBy, activeField, showModal}
    }
}
</script>
